import React from "react";

const About: React.FC = () => (
  <section className='aboutDisplay'>
    <p>
      I'm <strong>Alex Barth</strong>, a creative, entrepreneur and product-driven <strong>fullstack software engineer</strong>.
    </p>
    <p>
      Always interested in digital products and services, I'm focused on creating client-facing SaaS applications that scale. I create software that is convenient, practical, and enjoyable to use.
    </p>
    <p>
      I have built my own projects, such as <a className="aboutAnchor" target="_blank" href="https://tempo-music-player.web.app/"><strong>Tempo Music Player</strong></a>, which is a web-based music application which allows users to play music based on a BPM (Beats Per Minute) that they select, and have recently been working at an early <a className="aboutAnchor" target="_blank" href="https://www.healthnote.com/"><strong>Series A health-tech startup</strong></a> in an attempt to alleviate the high workload of clinics by creating smart tooling & platforms.
    </p>
    <p>
      I enjoy working on projects that expand the boundaries of tech, culture and business, and I'm currently looking for my next one.
    </p>
    <hr/>
    <p>
      <strong>Contact:</strong> <p><strong>Alex@AlexBarthGlobal.com</strong></p><br /><a className="aboutAnchor" target="_blank" href="https://drive.google.com/uc?id=11h_BtvC48RAfgmsZqEZSKALfVehSj4D6"><span><strong>Resume</strong></span></a>
    </p>
  </section>
);

export default About;