import React, { /*lazy,*/ Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
// const About = lazy(() => import("./About"));
// const Home = lazy(() => import("./Home"));
import About from './About'
import Home from './Home'

const App: React.FC = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <nav className='navBar'>
        <span>
        <NavLink exact to="/" className='navItem' id='websiteTitle'>Alex Barth</NavLink>
        </span>
        <span className='rightNavParent'>
        <NavLink exact to="/" className='navItem rightNavItem' activeClassName='activeNavLink'>Portfolio</NavLink>
        <NavLink exact to="/about" className='navItem rightNavItem' activeClassName='activeNavLink'>About</NavLink>
        <a href='https://github.com/AlexBarthGlobal' target="_blank" rel='noreferrer' className='navItem rightNavItem'>GitHub</a>
        {/* <a href='https://www.linkedin.com/in/alexbarthglobal/' target="_blank" rel='noreferrer' className='navItem rightNavItem'>LinkedIn</a> */}
        </span>
      </nav>
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Suspense>
  </Router>
);

export default App;