import React from "react";
import FendiFradoPic2022B from './images/FendiFradoPic2022B.png'
import carDealership from './images/carDealership.png'
import iPhone from './images/iPhone.png'
import macBook from './images/macBook.png'
import designerwatches from './images/designerwatches.png'
import wanderImg from './images/WanderImg.png'
import tempoImg from './images/tempoMusicPlayer.png'

const Home: React.FC = () => (
  <div className='portfolioDisplay'>

  <div className='portfolioPiece'>
    <div className='portfolioInnerWeb'>
        <a href="https://tempo-music-player.web.app/" target="_blank" rel='noreferrer' className='webItem' onMouseEnter={(e) => {
        e.currentTarget.querySelectorAll('p, img').forEach((element) => {
          if (element.localName === 'img' && element.id !== 'macBook') element.className = 'specialImageHover';
          if (element.localName === 'p') element.className = 'hoverText';
        })
      }}
        onMouseLeave={(e) => {
          e.currentTarget.querySelectorAll('p, img').forEach((element) => {
            if (element.localName === 'img' && element.id !== 'macBook') element.className = 'imgNotSelected';
            if (element.localName === 'p') element.className = '';
          })
        }}>

        <div className='webImageDiv'>
          <img src={macBook} alt='' id='macBook'></img>
          <img src={tempoImg} alt='' id="imgTempo"></img>
        </div>
        <div className='descriptionText'>
          <p id='itemTitle'>Tempo Music Player</p>
          <p>React, Redux, Express, PostgreSQL, Material-UI</p>
        </div>
      </a>
        <a href="https://github.com/AlexBarthGlobal/tempo-music-player" target="_blank" rel='noreferrer' className='repoText'><p>Repo</p></a>
    </div>
  </div>

  <div className='portfolioPiece'>
    <div className='portfolioInner'>
        <a href="https://expo.io/@alexbarth/projects/Wandered-Off" target="_blank" rel='noreferrer' className='mobileItem' onMouseEnter={(e) => {e.currentTarget.querySelectorAll('p, img').forEach((element) => {
            if (element.localName === 'img' && element.id !== 'iPhone') element.className='specialImageHover';
            if (element.localName ==='p') element.className='hoverText';
          })}}
          onMouseLeave={(e) => {e.currentTarget.querySelectorAll('p, img').forEach((element) => {
            if (element.localName === 'img' && element.id !== 'iPhone') element.className='imgNotSelected';
            if (element.localName ==='p') element.className='';
          })}}>
        
          <div className='imageDiv'>
            <img src={iPhone} alt='' id='iPhone'></img>
            <img src={wanderImg} alt='' id="imgMobile"></img>
          </div>
          <div className='descriptionText'>
            <p id='itemTitle'>Wander</p>
            <p>React Native, Firebase,<br></br>Google Maps,<br></br>Geolocation API</p>
          </div>
      </a>
      <a href="https://github.com/The-LAN-Before-Time/WanderedOff" target="_blank" rel='noreferrer' className='repoText mobileItem'><p>Repo</p></a>
    </div>
  </div>

  <div className='portfolioPiece'>
    <div className='portfolioInnerWeb'>
        <a href="https://www.fendifrado.com/" target="_blank" rel='noreferrer' className='webItem' onMouseEnter={(e) => {e.currentTarget.querySelectorAll('p, img').forEach((element) => {
            if (element.localName === 'img' && element.id !== 'macBook') element.className='specialImageHover';
            if (element.localName ==='p') element.className='hoverText';
          })}}
          onMouseLeave={(e) => {e.currentTarget.querySelectorAll('p, img').forEach((element) => {
            if (element.localName === 'img' && element.id !== 'macBook') element.className='imgNotSelected';
            if (element.localName ==='p') element.className='';
          })}}>
        
          <div className='webImageDiv'>
            <img src={macBook} alt='' id='macBook'></img>
            <img src={FendiFradoPic2022B} alt='' id="imgWeb"></img>
          </div>
          <div className='descriptionText'>
          <p id='itemTitle'>FENDI FRADO Music</p>
          <p>React</p>
          </div>
      </a>
    </div>
  </div>

  {/* <div className='portfolioPiece'>
    <div className='portfolioInnerWeb'>
        <a href="http://alexbarth-e-commerce-site.herokuapp.com/" target="_blank" rel='noreferrer' className='webItem' onMouseEnter={(e) => {e.currentTarget.querySelectorAll('p, img').forEach((element) => {
            if (element.localName === 'img' && element.id !== 'macBook') element.className='specialImageHover';
            if (element.localName ==='p') element.className='hoverText';
          })}}
          onMouseLeave={(e) => {e.currentTarget.querySelectorAll('p, img').forEach((element) => {
            if (element.localName === 'img' && element.id !== 'macBook') element.className='imgNotSelected';
            if (element.localName ==='p') element.className='';
          })}}>
        
          <div className='webImageDiv'>
            <img src={macBook} alt='' id='macBook'></img>
            <img src={carDealership} alt='' id="imgWeb"></img>
          </div>
          <div className='descriptionText'>
            <p id='itemTitle'>Mock Car Dealership</p>
            <p>React, Redux, Express, PostgreSQL</p>
          </div>
      </a>
      <a href="https://github.com/AlexBarthGlobal/Mock-Car-Dealership" target="_blank" rel='noreferrer' className='repoText'><p>Repo</p></a>
    </div>
  </div> */}

  {/* <div className='portfolioPiece'>
    <div className='portfolioInnerWeb'>
        <a href="http://designerwatches.alexbarthglobal.com/" target="_blank" rel='noreferrer' className='webItem' onMouseEnter={(e) => {e.currentTarget.querySelectorAll('p, img').forEach((element) => {
            if (element.localName === 'img' && element.id !== 'macBook') element.className='specialImageHover';
            if (element.localName ==='p') element.className='hoverText';
          })}}
          onMouseLeave={(e) => {e.currentTarget.querySelectorAll('p, img').forEach((element) => {
            if (element.localName === 'img' && element.id !== 'macBook') element.className='imgNotSelected';
            if (element.localName ==='p') element.className='';
          })}}>
        
          <div className='webImageDiv'>
            <img src={macBook} alt='' id='macBook'></img>
            <img src={designerwatches} alt='' id="imgWeb"></img>
          </div>
          <div className='descriptionText'>
            <p id='itemTitle'>Mock Designer Watch Shop</p>
            <p>Wordpress</p>
          </div>
      </a>
    </div>
  </div> */}

  <div>
    
  </div>

  </div>
);

export default Home;